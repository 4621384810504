<template>
	<div class="row common-header-container">
		<v-dialog v-model="error_enable" persistent max-width="290">
			<v-card>
				<v-card-title style="word-break:normal">
					{{$t("Warning_Message.Something_Went_Wrong")}}
				</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="error_enable = false;">
					{{$t("Warning_Message.Ok")}}
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="code_error_enable" persistent max-width="290">
			<v-card>
				<v-card-title style="word-break:normal">
					{{$t("Warning_Message.Something_Went_Wrong")}}
				</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="redirectToHomePage()">
					{{$t("Warning_Message.Home_Page")}}
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<div class="col-8 header-left-component">
			<img class="brand-logo-class clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/MyMedicine_Logo.svg" @click="redirectToCommonHome()">

			<!-- Search Segment -->
			<div class="search-segment">  
				<v-text-field v-model="valueInSearchBar" 
					solo flat placeholder="Search" hide-details="auto" background-color="#F4F6FA" class="ma-0 pa-0 body-text search-bar"
					@click="openDialog" @keyup.enter="enterPressedForSearch(valueInSearchBar)" @click:append="openDialog" :value="searchQuery" @input="updateSearch"
					ref="searchBox"
					>
					<template #append>
						<img class="" src="https://s3iconimages.mymedicine.com.mm/search_common_home_icon.svg" alt="Search Icon" @click="openDialog">
					</template>
				</v-text-field>

				<div id="search-container" v-if="dialogVisible" hide-overlay class="overlay">
					<div class="fullComponentOfSearchWeb">
						<div class="pb-4 recent-searches-Section">
							<p class="headingOfSearchComponent">
								{{ $t("Customer.Home.Recent_Searches") }}
							</p>
							<div 
								class="line">
							</div>
							<v-chip-group
								column 
								multiple
								class="searchItemsTab"
							>
								<v-chip
									v-for="searchItem in reversedQueue()"
									label
									link
									@click="enterPressedForSearch(searchItem)"
									class="item"
									:key="searchItem"
								>
									{{ searchItem }}
								</v-chip>
							</v-chip-group>
						</div>
						
						<v-divider></v-divider>
						
						<div class="show-search-results-Section" v-if="enterPressed || searchQuery.length>3 || (valueInSearchBar.length>0 && valueInSearchBar.toLocaleLowerCase()==searchResultTopicFromDB.toLocaleLowerCase())" :key="searchQueryComponent">
							<div v-if="teleconsultationSearchLoading || epharmacySearchingLoading || (searchQuery.length>0 && searchQuery.toLocaleLowerCase()!=searchResultTopicFromDB.toLocaleLowerCase())">
								<v-skeleton-loader elevation="0" type="heading" class="pl-4 py-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="heading" class="pl-4 py-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="heading" class="pl-4 py-2"></v-skeleton-loader>
							</div>
							<div v-else>
								<!-- Specializations -->
								<div v-if=" teleconsultationSearchLoading === false && searchSpecializationResultsFromDB && searchSpecializationResultsFromDB.length > 0" class="specializations-section" >
									<p class="headingOfSearchComponent">
										{{ $t("Customer.AllSpecializationsPage.Specializations")}}
										<span>({{ searchSpecializationResultsFromDB.length }})</span>
									</p>
									
									<div class="line"></div>

									<div class="listResults" v-for="result, index in searchSpecializationResultsFromDB">
										<v-btn
											elevation="0"
											class="ma-2 px-0 listItemButton dark-grey-color-text"
											@click="gotoSpecializationPage(result)"
										>
											{{ result.specialization_name }}
											<img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg" align="right">
										</v-btn>
										<v-divider
											v-if="result != searchSpecializationResultsFromDB[searchSpecializationResultsFromDB.length]"
											class="line2"
										>
										</v-divider>
									</div>
								</div>
								<!-- Doctors -->
								<div v-if=" teleconsultationSearchLoading === false && searchDoctorResultsFromDB && searchDoctorResultsFromDB.length > 0" class="doctors-section" >
									<p class="headingOfSearchComponent">
										{{ $t("Customer.Home.Doctors")}}
										<span>({{ searchDoctorResultsFromDB.length }})</span>
									</p>
									
									<div class="line"></div>

									<div class="listResults" v-for="result in searchDoctorResultsFromDB">
										<v-btn
											elevation="0"
											class="ma-2 px-0 listItemButton dark-grey-color-text"
											@click="gotoDoctorPage(result)"
										>
											{{ result.doctor_name }}
											<img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg" align="right">
										</v-btn>
										<v-divider
											v-if="result != searchDoctorResultsFromDB[searchDoctorResultsFromDB.length]"
											class="line2"
										>
										</v-divider>
									</div>
								</div>
								<!-- Products -->
								<div v-if="epharmacySearchingLoading === false && searchProductResultsFromDB && searchProductResultsFromDB.length > 0" class="doctors-section" >
									<p class="headingOfSearchComponent">
										{{ $t("Customer.Home.Products")}}
										<span>({{ searchProductResultsFromDB.length }})</span>
									</p>
									
									<div class="line"></div>

									<div class="listResults" v-for="result in searchProductResultsFromDB">
										<v-btn
											elevation="0"
											class="ma-2 px-0 listItemButton dark-grey-color-text"
											@click="gotoProductPage(result)"
										>
											{{ result.name }}
											<img src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg" align="right">
										</v-btn>
										<v-divider
											v-if="result != searchProductResultsFromDB[searchProductResultsFromDB.length]"
											class="line2"
										>
										</v-divider>
									</div>
								</div>
								
								<!-- No Search Results -->
								<div v-if="searchSpecializationResultsFromDB.length === 0 && searchDoctorResultsFromDB.length === 0 && searchProductResultsFromDB.length === 0">
									<p class="headingOfSearchComponent">
										{{$t("Customer.Home.Search_Results")}}
										<span>({{ searchSpecializationResultsFromDB.length + searchDoctorResultsFromDB.length + searchProductResultsFromDB.length }})</span>
									</p>
									<div class="no-result">
										<img src="https://s3iconimages.mymedicine.com.mm/NoSearchResultBrowser.svg" alt="No Search Result Image">
										<p class="no-result-msg">{{ $t("Customer.Home.Sorry_No_Search_Results") }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="shop-by-categories-Section">
						</div>
						
						<div class="frequently-searched-products-Section">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-4 header-right-component">
			<!-- Language Segment -->
			<div class="language-segment">
				<div class="pl-2">
					<img v-if="selectedLanguage === 'mm'" class="d-flex justify-center align-center icon-class" src="https://s3iconimages.mymedicine.com.mm/mm_lang_common_icon.svg" alt="language-icon">
					<img v-else class="d-flex justify-center align-center icon-class" src="https://s3iconimages.mymedicine.com.mm/.svg" alt="language-icon">
				</div>
				
				<v-select solo flat :items="languageList" v-model="selectedLanguage" item-text="language" item-value="value" @change="changeLanguage(selectedLanguage)" class="ma-0 pa-0 body-text language-select" >
				</v-select>
			</div>

			<img class="mr-2 search-icon icon-class" src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="search-icon" @click="redirectToSearchPage()">

			<div v-if="!hide_cart" class="d-flex align-center cart-icon clickable-pointer" @click="redirectToCartPage()">
				<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/header_cart_blue_bg_icon.svg" alt="cart-icon">
				<div v-if="cartQuantity && cartQuantity != 0" class="d-flex justify-center align-center white-background products-length-container">
					<div class="d-flex justify-center align-center light-red-background products-length">
						<p class="ma-0 white-color-text body-small-font font-weight-semi-bold">{{ cartQuantity }}</p>
					</div>
				</div>
			</div>

			<!-- <img class="profile-icon icon-class" src="https://d3vsnl8idgwrlw.cloudfront.net/male.svg" alt="cart-icon"> -->
			<div class="userProfileMainDiv">
				<div v-if="token" class="userProfileDiv">
					<img class="profile-icon icon-class" src="https://s3iconimages.mymedicine.com.mm/male.svg" alt="cart-icon" @click="profileDropdownList = !profileDropdownList">
					<img src="https://s3iconimages.mymedicine.com.mm/profileDropdownDown.svg" alt="down" v-if="!profileDropdownList" @click="profileDropdownList = true">
					<img src="https://s3iconimages.mymedicine.com.mm/profileDropdownUp.svg" alt="up" v-if="profileDropdownList" @click="profileDropdownList = false">
				</div>
				<div class="userProfileChoices profileoverlay" v-if="profileDropdownList">
					<v-btn elevation="0" class="text-transform-none-class profileOptionsButton" style="margin-bottom: 5px;" @click="gotoProfilePage(), profileDropdownList = false">
						<img src="https://s3iconimages.mymedicine.com.mm/profileAccountIcon.svg"
							height="28px" width="28px" style="margin-right: 10px;"
						/>
						<div>
							<p class="ma-0 body-text" style="text-align: left;">{{ $t("Login.Signup.Account") }}</p>
						</div>
					</v-btn>
					<v-btn elevation="0" class="text-transform-none-class profileOptionsButton" @click="logoutUser(), profileDropdownList = false">
						<img src="https://s3iconimages.mymedicine.com.mm/user_logout.svg"
						height="28px" width="28px" style="margin-right: 10px;"
						/>
						<div>
							<p class="ma-0 body-text" style="text-align: left;">{{ $t("Customer.UserProfile.Logout")}}</p>
						</div>
					</v-btn>
				</div>
			</div>
			<v-btn v-if="!token" elevation="0" class="userLoginButton text-transform-none-class font-weight-semi-bold light-grey-color-text" @click="openLoginPopup()">
				{{ $t("Login.LoginPage.Sign_In") }}
			</v-btn>
		</div>


		<!-- SIGNUP POPUP -->
		<v-dialog
			persistent
			v-model="signupPopup"
			max-width="600px"
			class="loginDialog"
			width="500px"
			>
			<v-card class="loginCard">
				<div
					class="closeButton"
				>
					<img
						class="clickable-pointer" 
						v-on:click="signupPopup = false, resetAllData()"
						src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg"
					/>
				</div>
				<div class="loginDiv">
					<h1 class="loginText ma-0">{{ $t("Login.Signup.Get_Started") }}</h1>
					<p class="welcomeBackText">
						{{ $t("Login.Signup.Please_Sign_Up") }}
					</p>
				</div>
				<div style="margin-top:48px; overflow:scroll; overflow-x: hidden !important; height:70vh;" align="left">
					<div class="Name">
					<p class="font_size_12_400">{{ $t("Login.Signup.Full_Name") }}</p>
					<img src="@/assets/Name_icon.png" align="left" style="width:26px ;height:26px" />
					<v-col cols="12" >
						<v-text-field type="text" background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="fullName">
						</v-text-field>
					</v-col>
					</div>

					<div class="MobileNumber">
					<p class="font_size_12_400">{{ $t("Login.Signup.Mobile_Number") }}</p>
					<img src="https://s3iconimages.mymedicine.com.mm/Mobile_Number.svg" align="left" style="width:26px ;height:26px;" />
					<v-col cols="12" >
						<v-text-field type='number' prefix="+95" background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="mobileNumber" :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM" placeholder="09*********">
						</v-text-field>
					</v-col>
					</div>

					<div class="Email">
					<p class="font_size_12_400">{{ $t("Login.Signup.Email") }}</p>
					<img src="@/assets/Email.png" align="left" style="width:26px ;height:26px" />
					<v-col cols="12" >
						<v-text-field type='email' background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="email">
						</v-text-field>
					</v-col>
					</div>

					<div class="NRC">
					<p class="font_size_12_400">{{ $t("Login.Signup.NRC") }}</p>
					<img src="@/assets/NRC.png" align="left" style="width:26px ;height:26px" />
					<v-col cols="12" >
						<v-text-field type='text' background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="nrc">
						</v-text-field>
					</v-col>
					</div>

					<div class="NRC">
					<p class="font_size_12_400">{{ $t("Login.Signup.Gender") }}</p>
					<img src="@/assets/NRC.png" align="left" style="width:26px ;height:26px" />
					<v-col cols="12" >
						<v-select
						v-if="$i18n.locale=='en'"
						:items="genderList"
						return-object
						v-model="gender"
						style="margin-top:-30px; margin-left: 16px;"
						:rules="genderRules"
						background-color="#F4F6FA"
							item-text = "en_text"
						>
						</v-select>
						<v-select
						v-if="$i18n.locale=='mm'"
						:items="genderList"
						v-model="gender"
						style="margin-top:-30px; margin-left: 16px;"
						:rules="genderRulesMM"
						background-color="#F4F6FA"
							item-text = "mm_text"
							return-object
						>
						</v-select>
					</v-col>
					</div>

					<div class="Date_of_birth">
						<p class="font_size_12_400">{{ $t("Login.Signup.Date_Of_Birth") }}</p>
						<div style="display: flex; flex-direction: row">
							<div>
								<v-menu
									ref="menu"
									v-model="menu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									>
									<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="dateOfBirth"
										background-color="#F4F6FA"
										prepend-icon="mdi-calendar"
										v-bind="attrs"
										v-on="on"
										style="border-radius:8px;"
										:rules="dobRule"
										@change="get_age(dateOfBirth)"
									></v-text-field>
									</template>
									<v-date-picker
										v-model="dateOfBirth"
										:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
										min="1900-01-01"
										style="width:90%"
										@change="get_age(dateOfBirth),menu= false"
									></v-date-picker>
								</v-menu>
							</div>
						</div>
					</div>


					<div style="margin-top:10px;font-size:90%; display:flex; flex-direction: row;" v-if="$i18n.language=='en'">
					<input type="checkbox" style="width:25px; height:16px;" v-model="t_c" /> &nbsp; {{ $t("Login.Signup.Agree") }}<a href="/customer/epharmacytermsandconditions" style="text-decoration: none;">{{ $t("Login.Signup.Terms_And_Conditions") }}</a>
					</div>

					<div style="margin-top:10px;font-size:90%; display:flex; flex-direction: row;" v-else>
					<input type="checkbox" style="width:25px; height:16px;" v-model="t_c" /> &nbsp; <a href="/customer/epharmacytermsandconditions" target="_blank" style="text-decoration: none;">{{ $t("Login.Signup.Agree") }}</a> &nbsp;{{ $t("Login.Signup.Terms_And_Conditions") }}
					</div>

					<div style="margin-top:10px;">
						<v-btn 	elevation="0" class ="signupButtonInSignup signUpButton" 
								:loading="signupClicked"
								large style="width: 100%" @click="newUserSigningUp()" 
								:disabled="isButtonClicked2 || !(fullName.trim() != '' && mobileNumber != '' && dateOfBirth != '' && email !='' && nrc !='' && mobileNumber.length >= 7 && mobileNumber.length <=11 && dateOfBirth != '' && email !='' && nrc !='' && gender)"
							>
							{{ $t("Login.Signup.Signup") }}
						</v-btn>
					</div>
				</div>
				<v-divider class="loginDivider"></v-divider>
				<div class="newUserDiv">
					<p class="ma-0">{{ $t("Login.LoginPage.Existing_User") }}?</p>
					<v-btn class="signUpButton" @click="goToSignInFlow">
						{{$t("Login.LoginPage.Sign_In")}}
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
		<!-- Age limit Error -->
		<div v-if="ageLimitModal">
			<v-row justify="center">
				<v-dialog v-model="ageLimitModal" persistent max-width="290">
					<v-card>
					<v-card-title style="word-break: normal">
					{{$t("Warning_Message.Invalid_Account_YouShould_Be_Above18To_Have_An_Account")}}
					</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
						color="primary"
						depressed
						@click="closeAgeLimitModal"
						>
						{{$t("Warning_Message.Ok")}}
						</v-btn>
					</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- DOB invalid format -->
		<div v-if="invalidFormatFlag">
			<v-row justify="center">
				<v-dialog v-model="invalidFormatFlag" persistent max-width="290">
					<v-card>
					<v-card-title style="word-break: normal">
					{{$t("Warning_Message.DOB_Invalid_Format")}}
					<!-- Date of Birth is in invalid format (Must be in YYYY-MM-DD format) -->
					</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
						color="primary"
						depressed
						@click="invalidFormatPopup"
						>
						{{$t("Warning_Message.Ok")}}
						</v-btn>
					</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- User Registration Failed -->
		<div v-if="userRegistrationModel">
			<v-row justify="center">
				<v-dialog v-model="userRegistrationModel" max-width="290">
				<v-card>
					<v-card-title style="word-break:normal">
					{{$t("Warning_Message.User_Registration_Failed")}}
					</v-card-title>
					<v-card-text
					></v-card-text
					>
					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						align="center"
						depressed
						color="primary"
						@click="closeUserRegistrationModel"
						>
						{{$t("Warning_Message.Ok")}}
					</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- T&C fail -->
		<div v-if="TermsError">
			<v-row justify="center">
				<v-dialog v-model="TermsError" max-width="290">
				<v-card>
					<v-card-title style="word-break:normal">
					{{$t("Warning_Message.Please_Agree_Terms_And_condition")}}
					</v-card-title>
					<v-card-text
					></v-card-text
					>
					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						align="center"
						depressed
						class="signUpButton okButton"
						@click="closeTermsError"
						>
						{{$t("Warning_Message.Ok")}}
					</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- Ph No error -->
		<div v-if="phoneNumberError">
			<v-row justify="center">
				<v-dialog v-model="phoneNumberError" max-width="290">
				<v-card>
					<v-card-title style="word-break:normal">
					{{$t("Warning_Message.Please_Enter_Your_Number_With0In_The_Beginning")}}
					</v-card-title>
					<v-card-text
					></v-card-text
					>
					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						align="center"
						depressed
						class="signUpButton"
						@click="phoneNumberError = false, isLoginClicked = false, signupClicked=false"
						>
						{{$t("Warning_Message.Ok")}}
					</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- User already Registered -->
		<div v-if="alreadyRegisteredUser">
			<v-row justify="center">
				<v-dialog v-model="alreadyRegisteredUser" persistent max-width="290">
				<v-card>
					<v-card-title style="word-break:normal">
						{{$t("Warning_Message.User_Already_Registered")}}
					</v-card-title>
					<v-card-text
					></v-card-text>
					<v-card-actions>
					<v-spacer></v-spacer>
					<div  style="display:flex; flex-direction:row; justify-content: space-between; width:100%">
						<div>
						<v-btn class="signUpButton" text @click="openLoginPopup(), userLoginAttempt=true, userSignUpAttempt=false, signupClicked=false">
							{{$t("Warning_Message.Login")}}
						</v-btn>
						</div>
						<div>
						<v-btn class="signUpButton" text @click="alreadyRegisteredUser = false, signupClicked=false" >
						{{$t("Warning_Message.Cancel")}}
						</v-btn>
						</div>
					</div>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-row>
		</div>
		<!-- Account Created Successfully -->
		<div v-if="isLoadingSignupPage">
			<v-row justify="center">
				<v-dialog v-model="isLoadingSignupPage" persistent max-width="400px">
				<v-card class="accountSuccess">
					<img class="accountSuccessImage" src="https://s3iconimages.mymedicine.com.mm/OrderSuccessImageFull.svg">
					<v-card-title style="word-break: normal" class="accountSuccessText">
						{{ $t("Login.LoginPage.Account_Created_Successfully") }}
					</v-card-title>
				</v-card>
				</v-dialog>
			</v-row>
		</div>

		<!-- LOGIN POPUP -->
		<v-dialog
			persistent
			v-model="loginPopup"
			max-width="600px"
			width="500px"
			class="loginDialog"
			>
			<v-card class="loginCard">
				<div
					class="closeButton"
				>
					<img
						class="clickable-pointer"
						v-on:click="loginPopup = false, isLoginClicked = false, userLoginAttempt=false, userSignUpAttempt=false, resetAllData()"
						src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg"/>
				</div>
				<div class="loginDiv">
					<h1 class="loginText ma-0">{{ $t("Login.LoginPage.Sign_In") }}</h1>
					<p class="welcomeBackText title-heading">
						{{ $t("Login.LoginPage.Welcome_Back") }}
					</p>
				</div>
				<div>
					<div class="mobileNumDiv">
						<p class="ma-0 px-4">{{ $t("Login.LoginPage.Mobile_Number") }}</p>
						<div class="mobileNumInnerDiv pa-0">
							<img
								src=https://s3iconimages.mymedicine.com.mm/Mobile_Number.svg"
								class="px-4"
							/>
							<v-text-field
								class="mobileNumTextField"
								background-color="#F4F6FA"
								prefix="+95"
								placeholder="09*********"
								type="number"
								:rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
								v-model="phone_number"
								>
							</v-text-field>
						</div>
					</div>
				</div>
				<div class="sendOTPButtonDiv">
					<v-btn class="sendOTPButton"
						v-bind:disabled="phone_number.length > 11 || phone_number.length < 7 || isLoginClicked"
						@click="LoginWithOTP()"
					>
					{{ $t("Login.LoginPage.Login") }}
					</v-btn>
				</div>
				<div v-if="isLoginClicked">
					<v-progress-circular
						indeterminate
						color="#48ACEF"
						style="margin-top: 5px;"
					></v-progress-circular>
					<p>{{ $t("Login.LoginPage.Sending_OTP") }}</p>
				</div>
				<v-divider class="loginDivider"></v-divider>
				<div class="newUserDiv">
					<p class="ma-0">{{ $t("Login.LoginPage.New_User") }}</p>
					<v-btn class="signUpButton" @click="loginPopup = false, signupPopup = true, userLoginAttempt=false, userSignUpAttempt=true">
						{{ $t("Login.LoginPage.Signup") }}
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
		<!-- Login Error Popup -->
		<div v-if="auth_error_enable">
			<v-row justify="center">
				<v-dialog v-model="auth_error_enable" persistent max-width="290">
				<v-card>
					<v-card-title style="word-break: normal;" class="d-flex justify-center">
						{{errorMessage}}
					</v-card-title>
					<v-card-actions>
					<v-spacer></v-spacer>
					<!-- When the User does not have an account -->
					<div v-if="customerUnauthorized" style="display:flex; flex-direction:row; justify-content: space-between; width:100%">
						<div>
						<v-btn class="signUpButton" text @click="closeDialogUnauthorized()" >
							{{$t("Warning_Message.Signup")}}
						</v-btn>
						</div>
						<div>
						<v-btn class="signUpButton" text @click="auth_error_enable = false;" >
						{{$t("Warning_Message.Cancel")}}
						</v-btn>
						</div>
					</div>
					<!-- When 3 tries are exceeded -->
					<div v-else-if="retries > 0 || customerUnauthorized" class="cancelDiv">
						<div>
							<v-btn class="signUpButton" text @click="closeDialogError" >
							{{ $t("Warning_Message.Cancel") }}
							</v-btn>
						</div>
					</div>
					<div v-else class="goBackResendDiv">
						<div>
							<v-btn class="signUpButton goBackButton" text @click="goBackFromOTPAuth()">
							{{ $t("Login.LoginPage.Go_Back") }}
							</v-btn>
						</div>
						<div>
							<v-btn class="signUpButton" text @click="resendOTP">
							{{ $t("Login.LoginPage.Resend_OTP") }}
							</v-btn>
						</div>
					</div>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-row>
		</div>

		<!-- OTP AUTH POPUP -->
		<v-dialog
			persistent
			v-model="otpAuthPopup"
			max-width="600px"
			width="500px"
			class="otpAuthDialog" >
			<v-card class="otpAuthDialogCard">
				<div
					class="backButton"
				>
					<img
						v-on:click="goBackFromOTPAuth()" 
						class="clickable-pointer" 
						src="https://s3iconimages.mymedicine.com.mm/back_button.svg"
					/>
				</div>
				<div class="otpDiv">
					<p class="otpHeading title-heading ma-0 font-weight-medium">{{ $t("Login.OTPSignupPage.OTP_Authentication") }}</p>
					<p v-if="$store.state.locale =='en'" class="otpMessageText body-heading">{{ $t("Login.OTPSignupPage.Auth_Message") }} (+95) <span style="color:green">{{phone_number}}</span> </p>
					<p v-else class="otpMessageText body-heading">{{ $t("Login.OTPSignupPage.Auth_Message") }} (+95) <span style="color:green">{{phone_number}}</span> {{ $t("Login.OTPSignupPage.Auth_second") }}</p>
				</div>
				<div  class="otpTextCenter" style="display: flex; flex-direction: row wrap; margin-top:40px; text-align: center;" align="center">
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP1,1)" v-model.number="inputOTP1" ref="ref1"/>
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP2,2)" v-model.number="inputOTP2" ref="ref2"/>
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP3,3)" v-model.number="inputOTP3" ref="ref3"/>
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP4,4)" v-model.number="inputOTP4" ref="ref4"/>
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP5,5)" v-model.number="inputOTP5" ref="ref5"/>
					<v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input" @keyup="moveNext(inputOTP6,6)" v-model.number="inputOTP6" ref="ref6"/>
				</div>
				<div style="padding: 12px;">
					<span> {{ $t("Login.LoginPage.Didnt_Receive_OTP") }} </span>
					<v-btn
						elevation=""
						class="signUpButton"
						large
						v-bind:disabled="isOTPSent || isButtonClicked"
						style="width: 80%; margin: 12px;"
						@click="resendOTP()"
					>
					{{ $t("Login.LoginPage.Resend_OTP") }}
					</v-btn>
					<div v-if="isOTPSent">
						<v-progress-circular indeterminate color="#48ACEF"></v-progress-circular>
						<p> {{ $t("Login.LoginPage.Sending_OTP") }}! </p>
					</div>
					<p style="padding: 10px;" v-if = "sentMessage && (!inputOTP1 && !inputOTP2 && !inputOTP3 && !inputOTP4 && !inputOTP5 && !inputOTP6) "> {{ $t("Login.LoginPage.OTP_Has_Been_Sent_Again") }} </p>
				</div>
				<div style="margin-top:30px;">
					<div v-if="isButtonClicked">
						<v-progress-circular indeterminate color="#48ACEF"></v-progress-circular>
						<p>{{ $t("Login.LoginPage.Signing_You_In") }}</p>
					</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_customer, axios_auth_instance_epharmacy_customer } from '../../utils/axios_utils';
import { handleError } from '../../utils/utils';

export default {
	name: 'CommonHomeHeader',
	props: {
		cartQuantity: Number,
		hide_cart: {
			type: Boolean,
			required: false
		},
		displayLoginPopup: {
			type: Boolean,
			required: false
		},
		callpermissionflag: {
			type: Boolean,
			required: true
		},
		errorDialogue: {
			type: Boolean,
			required: false
		},
		authDialogue: {
			type: Boolean,
			required: false
		}
		
	},
	data() {
		return {
			screenWidth: window.innerWidth,
			languageList: [
				{
					language:'MM',
					value:'mm'
				},
				{
					language:'EN',
					value:'en'
				}
			],
			selectedLanguage: '',
			dialogVisible: false,
			searchQuery: '',
			dialogStyle: {},
			recentSearches: [],
			searchResultTopicFromDB: '',
			searchSpecializationResultsFromDB: [],
			searchDoctorResultsFromDB: [],
			searchProductResultsFromDB: [],
			queue: [],
			noSearchResults: false,
			searchQueryComponent: 0,
			epharmacySearchingLoading: false,
			teleconsultationSearchLoading: false,
			displayLoginSignupButton: true,
			token: '',
			loginPopup: false,
      		auth_error_enable : false,
		  	error_enable : false,
			isLoading: false,
			displayLoginSignupButton: true,
			loginPopup: false,
			signupPopup: false,
			otpAuthPopup: false,
			phone_number: "",
			mobileNumberRules: [
				v => !!v || 'Mobile Number is required',
				v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
				v => v > 0 || 'Mobile number cannot be negative',
			],
      		mobileNumberRulesMM: [
				v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
				v => (v && v.length <= 11 && v.length >=7) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
				v => v > 0 || 'Mobile number cannot be negative',
			],
			signupClicked: false,
			isLoginClicked: false,
			isButtonClicked: false,
			errorMessage: '',
			inputOTP1: "",
			inputOTP2: "",
			inputOTP3: "",
			inputOTP4: "",
			inputOTP5: "",
			inputOTP6: "",
			isOTPSent: false,
			sentMessage: false,
			retries: -1,
			customerUnauthorized: false,
			fullName:"",
			ageLimitModal:"",
			menu:false,
			mobileNumber:"",
			dateOfBirth:"",
			email:"",
			nrc: "",
			t_c: "",
			genderList : [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
			genderRules : [v => !!v || 'Gender is required'],
			genderRulesMM : [v => !!v || 'ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။'],
			gender: "",
			TermsError : false,
			phoneNumberError: false,
			userRegistrationModel : false,
			alreadyRegisteredUser : false,
			translation_dob: "Date Of Birth",
			dobRule : [
			v => !!v || 'DOB is required',
			v => /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(v) || 'DOB must be in format "YYYY-MM-DD"',
			],
			isButtonClicked2: false,
			invalidFormatFlag : false,
			isLoadingSignupPage: false,
			userLoginAttempt: false,
			userSignUpAttempt: false,
			profileDropdownList: false,
			isMobile: false,
			valueInSearchBar: '',
			enterPressed: false,
			code_error_enable: false
		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
		document.addEventListener("click", this.handleClickOutside);

		this.isMobile = this.screenWidth <= 600 ? true : false;
		
		this.selectedLanguage = this.$store.state.locale;
		// sets the language to one in local storage. if not, default to mm
		if (!this.selectedLanguage) {
			this.selectedLanguage = 'mm';										//for user's selected language
			this.$i18n.locale = 'mm';											//for translation
			this.$store.dispatch("changeLocale", this.selectedLanguage);
		}
        else {
			this.$i18n.locale = this.selectedLanguage;							//for translation
			this.$store.dispatch("changeLocale", this.selectedLanguage);		//for storing in vuex
		}

		if (this.$cookies.get('customerToken')===null)
			this.displayLoginSignupButton = true;
		else {
			this.displayLoginSignupButton = false;
			this.token = this.$cookies.get('customerToken');
		}
		this.queue = JSON.parse(localStorage.getItem('searchQueue')) || [];
	},
	beforeUnmount() {
		document.removeEventListener("click", this.handleClickOutside);
		window.removeEventListener('resize', this.handleResize);
	},
	watch: {
		displayLoginPopup(newValue, oldValue) {
			this.loginPopup = newValue;
		},
		callpermissionflag(newValue,oldValue){
			if(newValue && this.token){
				this.getCartCount();
			}
		},
		errorDialogue(newValue, oldValue) {
			if (newValue && this.$route.name != 'CommonHomePage') {
				this.code_error_enable = true;
			} else if (newValue) {
				this.error_enable = true;
			}
		},
		authDialogue(newValue, oldValue) {
			if (newValue && this.token) {
				this.openLoginPopup();
			}
		}
	},
	methods: {
		redirectToHomePage() {
			console.log(this.$route.name);
			this.$router.push({
				name: 'CommonHomePage'
			});
		},
		enterPressedForSearch(value) {
			if(value.length > 0) {
				this.enterPressed = true;
				this.dialogVisible=true;
				this.searchQuery = value;

				this.getAllProductsForSearchQuery(this.searchQuery);
				this.addToRecentSearches();
				this.dialogVisible = true;
			}
		},
		getCartCount(){
			axios_auth_instance_epharmacy_customer.get('/cart/getCartCount')
			.then((getCartCountResponse) => {
				this.cartQuantity = getCartCountResponse.data.data.cart_count;
				
				this.$emit('getCartData', getCartCountResponse.data.data);
			})
			.catch((getCartCountError) => {
				console.log('Error fetching cart count: ', getCartCountError);

				if (getCartCountError.response.status == 401) {
					this.cartQuantity = null;
				} else {

				}
			});
		},
		handleResize() {
			this.screenWidth = window.innerWidth;
			this.isMobile = this.screenWidth <= 600 ? true : false;
		},
		logoutUser() {
			localStorage.removeItem("currentURL");
			var signOutData = {
				token: this.token,
				typeOfUser: "CUSTOMER"
			};
			axios
				.post(process.env.VUE_APP_BACKEND_URL + "/customerSignOut", signOutData)
				.then((customerSignOutResponse) => {
					this.$cookies.remove("customerToken");
					this.$store.dispatch("changeName", "");
					this.$store.dispatch("cleanVuex");
					// let userState = false
					// this.$emit('userLoggedIn', userState);
					this.cartQuantity = null;
					this.$store.dispatch("changeLocale", this.$i18n.locale);
					this.token = '';
					if (!window.xm) {
						this.$router.push({
							name: 'CustomerHomeScreen'
						});
					} else {
						if(this.$route.name == 'CommonHomePage')
						{
							this.$router.go();
						}
						else
						{
							this.$router.push({
								name: 'CommonHomePage'
							});
						}
					}
				})
				.catch((customerSignOutError) => {
					this.isLogingOut = false;
				});
		},
		gotoProfilePage() {
			const url = this.$router.resolve({ path: "/customer/profile" }).href;
			window.open(url, '_blank');
		},
		resetAllData() {
			this.inputOTP1 = "";
			this.inputOTP2 = "";
			this.inputOTP3 = "";
			this.inputOTP4 = "";
			this.inputOTP5 = "";
			this.inputOTP6 = "";

			this.fullName = "";
			this.ageLimitModal = "";
			this.menu = false;
			this.mobileNumber = "";
			this.dateOfBirth = "";
			this.email = "";
			this.nrc = "";
			this.gender = "";

			this.isButtonClicked2 = false;
			this.signupClicked = false;

			this.$emit('loginPopupClosed', this.loginPopup);
		},
		autoSignIn() {
			this.isButtonClicked = true;

			var customerLoginData = {
				otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
				mobileNumber: this.phone_number
			};

			axios
			.post(
				process.env.VUE_APP_BACKEND_URL + "/customerSignIn",
				customerLoginData
			)
			.then((customerLoginData) => {
				// User Agent.
				let userAgent = navigator.userAgent;
				if (userAgent.includes('kbzpay')) {
					this.$store.dispatch('changeUserType', 'APP');
					this.appFlag = true;
				} else {
					this.$store.dispatch('changeUserType', 'MOBILE');
				}

				this.isLoading = false
				this.otpAuthPopup = false;
				this.isLoadingSignupPage = true;

				this.$cookies.set("customerToken", customerLoginData.data.token,"1w");
				this.$cookies.set("access",customerLoginData.data.accessToken);

				this.fullName = "";
				this.ageLimitModal = "";
				this.menu = false;
				this.mobileNumber = "";
				this.dateOfBirth = "";
				this.email = "";
				this.nrc = "";
				this.gender = "";

				setTimeout(() => {
					this.otpAuthPopup=false;
					this.loginPopup = false;
					this.userLoginAttempt=false;
					this.userSignUpAttempt=false;
					this.isLoadingSignupPage = false;
				}, 2000);
			})
			.catch((customerLoginException) => {
				this.isLoginClicked = false
				this.isLoading = false
				if(customerLoginException.response.status == 400 || customerLoginException.response.status == 303 || customerLoginException.response.status == 401){
					this.auth_error_enable = true;
					this.errorMessage = "You do not have a registered account";
					this.customerUnauthorized = true;
				}
				else if(customerLoginException.response.status == 306){
					this.auth_error_enable = true;
					this.retries = customerLoginException.response.data.retries_left;
					if(this.$i18n.locale == "en" && this.retries > 0)
						this.errorMessage = "Invalid OTP";
					else if(this.$i18n.locale == "mm" && this.retries > 0)
						this.errorMessage = "OTP နံပါတ်မှားနေသည်";
					else if(this.$i18n.locale == "en" && this.retries == 0)
						this.errorMessage = "OTP Expired, please generate again";
					else
						this.errorMessage = "OTP အချိန်ကုန်သွားပါပြီ။ နောက်တစ်ကြိမ်ထပ်မံ ကြိုးစားပါ";
				}
				this.isButtonClicked = false;
				this.isOTPSent = false;
				this.inputOTP1 = "";
				this.inputOTP2 = "";
				this.inputOTP3 = "";
				this.inputOTP4 = "";
				this.inputOTP5 = "";
				this.inputOTP6 = "";
			});
		},
		get_age(dateString) {
			if(!(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(dateString))){
				console.log("Date not in correct format");
				this.dateOfBirth = "";
				this.invalidFormatFlag = true;
			}
			else{
				this.invalidFormatFlag = false;
			}

			var today = new Date();
			var birthDate = new Date(dateString);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			if(age < 18){
				this.ageLimitModal = true;
				this.dateOfBirth = "";
			}
			else {
				this.ageLimitNotInRange = false;
			}
		},
		closeAgeLimitModal(){
			this.ageLimitModal = false
		},
		invalidFormatPopup(){
			this.invalidFormatFlag = false;
		},
		newUserSigningUp() {
			this.signupClicked = true;
			this.phone_number = this.mobileNumber;

			if(this.t_c == true) {
				//call backend to verify if phone number exists
				this.isButtonClicked2 = true;
				if(this.mobileNumber[0] == "0") {
					var phoneNumberCheckData = {
						phoneNumber: this.mobileNumber
					}
					axios.post(process.env.VUE_APP_BACKEND_URL+"/checkIfPhoneNumberExists",phoneNumberCheckData)
					.then((checkIfPhoneNumberExistsResponse) =>{
						var otpBody = {
							phoneNumber: this.mobileNumber,
							email: this.email,
							newUserFlag: true
						};
						if(checkIfPhoneNumberExistsResponse.data.data == "NotExists"){
							otpBody.newUserFlag = true;
							otpBody.email = this.email;
						}
						else{
							otpBody.newUserFlag = false;
						}

						axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
						.then((sendOTPResponse) =>{
						})
						.catch((sendOTPError) =>{
							this.error_enable = true;
						});

						this.signupPopup = false;
						this.userLoginAttempt=false;
						this.userSignUpAttempt=true;
						this.otpAuthPopup = true;
						this.signupClicked = false;
					})
					.catch((checkIfPhoneNumberExistsError)=>{
						this.isButtonClicked2 = false;
						if(checkIfPhoneNumberExistsError.response.status == 305){
							this.alreadyRegisteredUser = true;
						}
					});
				}
				else {
					this.phoneNumberError = true;
					this.isButtonClicked2 = false;
				}
			}
			else {
				this.TermsError = true;
				this.isButtonClicked2 = false;
			}
		},
		verifyOTPandSignUpUser() {
			// from OTPSignupPage
			var otp_body = {
				otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
				phoneNumber: this.phone_number
			};
			axios.post(process.env.VUE_APP_BACKEND_URL+"/verifyOTP",otp_body)
			.then((verifyOTPResponse) => {
				var customerData = {
					customerName  : this.fullName,
					phoneNumber : this.phone_number,
					dob : this.dateOfBirth,
					email : this.email,
					gender :this.gender.key,
					nrc : this.nrc
				};
				axios.post(process.env.VUE_APP_BACKEND_URL + "/customerSignUp", customerData)
					.then((createCustomerResponse)=>{
						this.isButtonClicked = false;

						this.fullName = "";
						this.ageLimitModal = "";
						this.menu = false;
						this.mobileNumber = "";
						this.dateOfBirth = "";
						this.email = "";
						this.nrc = "";
						this.gender = "";

						this.autoSignIn();
					})
					.catch((createCustomerError)=>{
						if(createCustomerError.response.status == 305){
							this.alreadyRegisteredUser = true;
						}
						else if(createCustomerError.response.status == 302 || createCustomerError.response.status == 400){
							this.passwordValidation = true;
						}
					});
			})
			.catch((verifyOTPError) =>{
				if(verifyOTPError.response.status == 306) {
					this.auth_error_enable = true;
					this.retries = verifyOTPError.response.data.retries_left;
					if(this.$i18n.locale == "en" && this.retries > 0)
						this.errorMessage = "Invalid OTP";
					else if(this.$i18n.locale == "mm" && this.retries > 0)
						this.errorMessage = "OTP နံပါတ်မှားနေသည်";
					else if(this.$i18n.locale == "en" && this.retries == 0)
						this.errorMessage = "OTP Expired, please generate again";
					else
						this.errorMessage = "OTP အချိန်ကုန်သွားပါပြီ။ နောက်တစ်ကြိမ်ထပ်မံ ကြိုးစားပါ";
				}
				this.isButtonClicked = false;
				this.inputOTP1 = "";
				this.inputOTP2 = "";
				this.inputOTP3 = "";
				this.inputOTP4 = "";
				this.inputOTP5 = "";
				this.inputOTP6 = "";

				this.userSignUpAttempt = true;
				this.userLoginAttempt = false;
			});
		},
		closeUserRegistrationModel(){
			this.userRegistrationModel = false;
		},
		closeTermsError(){
			this.TermsError = false;
			this.signupClicked=false;
		},
		closeAlreadyRegisteredModel(){
			this.alreadyRegisteredUser = false;
			// this.$router.push({
			//   name : "Login"
			// })
		},
		OTP6DigitsEntered() {
			this.isButtonClicked = true;
			var otp_body = {
				otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
				phoneNumber: this.phone_number
			};
			var customerLoginData = {
				otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
				mobileNumber: this.phone_number
			};
			axios
			.post(
				process.env.VUE_APP_BACKEND_URL + "/customerSignIn",
				customerLoginData
			)
			.then((customerLoginData) => {
				// User Agent.
				let userAgent = navigator.userAgent;
				if (userAgent.includes('kbzpay')) {
					this.$store.dispatch('changeUserType', 'APP');
					this.appFlag = true;
				} else {
					this.$store.dispatch('changeUserType', 'MOBILE');
				}

				this.isLoading = false

				this.$cookies.set("customerToken", customerLoginData.data.token,"1w");
				this.$router.go();
			})
			.catch((customerLoginException) => {
				this.isLoginClicked = false
				this.isLoading = false
				if(customerLoginException.response.status == 400 || customerLoginException.response.status == 303 || customerLoginException.response.status == 401){
					this.auth_error_enable = true;
					this.errorMessage = "You do not have a registered account";
					this.customerUnauthorized = true;
				}
				else if(customerLoginException.response.status == 306){
					this.auth_error_enable = true;
					this.retries = customerLoginException.response.data.retries_left;
					if(this.$i18n.locale == "en" && this.retries > 0)
						this.errorMessage = "Invalid OTP";
					else if(this.$i18n.locale == "mm" && this.retries > 0)
						this.errorMessage = "OTP နံပါတ်မှားနေသည်";
					else if(this.$i18n.locale == "en" && this.retries == 0)
						this.errorMessage = "OTP Expired, please generate again";
					else
						this.errorMessage = "OTP အချိန်ကုန်သွားပါပြီ။ နောက်တစ်ကြိမ်ထပ်မံ ကြိုးစားပါ";
				}
				this.isButtonClicked = false;
				this.isOTPSent = false;
				this.inputOTP1 = "";
				this.inputOTP2 = "";
				this.inputOTP3 = "";
				this.inputOTP4 = "";
				this.inputOTP5 = "";
				this.inputOTP6 = "";
			});
		},
		resendOTP() {
			this.auth_error_enable = false;
			this.isOTPSent = true;
			this.sentMessage = false;
			
			var otpBody = {
				phoneNumber: (this.mobileNumber) ? (this.mobileNumber) : (this.phone_number),
				email: this.email,
				newUserFlag: (this.userSignUpAttempt) ? true : false
			};
			axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
				.then((sendOTPResponse) =>{
					this.isOTPSent = false;
					this.sentMessage = true;
					this.inputOTP1 = "";
					this.inputOTP2 = "";
					this.inputOTP3 = "";
					this.inputOTP4 = "";
					this.inputOTP5 = "";
					this.inputOTP6 = "";
					this.$refs["ref1"].$refs.input.focus();
				})
				.catch((sendOTPError) =>{
					this.error_enable = true;
					this.otpAuthPopup = true;
					this.isOTPSent = false;
					this.sentMessage = true;
					this.auth_error_enable = false;
				});

			// verifyOTPandSignUpUser();
		},
		moveNext(value,current_place) {

			this.sentMessage = false;
			if(value <= 9 && typeof value == "number"){
				if(current_place != 6) {
					this.$refs["ref"+(parseInt(current_place)+1).toString()].$refs.input.focus()
				}
				else if(current_place == 6) {
					this.isButtonClicked = true;
					this.auth_error_enable = false;

					if (this.userLoginAttempt || this.userSignUpAttempt==false) {
						this.OTP6DigitsEntered();
					}
					else if(this.userSignUpAttempt || this.userLoginAttempt==false) {
						this.verifyOTPandSignUpUser();
					} else {
						// console.log('Error in OTP verification');
					}
					this.userLoginAttempt=false;
					this.userSignUpAttempt=false;
						
					this.$refs["ref1"].$refs.input.focus()
				}
			}
			else if(value == "") {
				if(current_place !=1) {
					this["inputOTP"+(parseInt(current_place)-1).toString()] = "";
					this.$refs["ref"+(parseInt(current_place)-1).toString()].$refs.input.focus();
				}
			}
		},
		LoginWithOTP(){
			this.userLoginAttempt = true;
			this.isLoginClicked = true;
			if(this.phone_number[0] == "0")
			{
				var otpBody = {
					phoneNumber: this.phone_number
				};
				axios.post(process.env.VUE_APP_BACKEND_URL+"/checkIfPhoneNumberExists",otpBody)
					.then((checkIfPhoneNumberExistsResponse) =>{
						this.isLoginClicked = false
						this.isLoading = false
						this.auth_error_enable = true;
						if(this.$i18n.locale == 'en'){
							this.errorMessage = "You do not have a registered account";
						}
						else{
							this.errorMessage = "သင်အကောင့်မဖွင့်ရသေးပါ"
						}

						this.customerUnauthorized = true;
					})
				.catch((checkIfPhoneNumberExistsError) =>{
					if(checkIfPhoneNumberExistsError.response.status == 305){
						axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
							.then((sendOTPResponse) =>{
								this.loginPopup = false;
								this.otpAuthPopup = true;
							})
							.catch((sendOTPError) =>{
								this.error_enable = true;
							});
					}
					else {
						this.error_enable = true;
					}
				});
			}
			else {
				this.phoneNumberError = true;
			}
		},
		closeDialogUnauthorized() {
			this.signupPopup = true;
			this.userLoginAttempt=false;
			this.userSignUpAttempt=true;

			this.customerUnauthorized=false;
			this.loginPopup=false;
			this.auth_error_enable=false;
		},
		goBackFromOTPAuth() {
			this.otpAuthPopup = false;
			this.auth_error_enable = false;
			if (this.userLoginAttempt) {
				if (!this.isMobile) {
					this.loginPopup = true;
				} else {
					// Mobile - Redirect to login page.
					this.$router.push({
						name: 'Login'
					});
				}
			} else
				this.signupPopup = true;
			
			this.isLoginClicked = false;
			this.isButtonClicked = false;
			this.isButtonClicked2 = false;
			this.isOTPSent = false;
			this.inputOTP1 = "";
			this.inputOTP2 = "";
			this.inputOTP3 = "";
			this.inputOTP4 = "";
			this.inputOTP5 = "";
			this.inputOTP6 = "";
		},
		openLoginPopup() {
			if (!this.isMobile) {
				this.loginPopup = true;
			} else {
				// Mobile - Redirect to login page.
				this.$router.push({
					name: 'Login'
				});
			}
		},
		goToSignInFlow() {
			this.signupPopup = false;
			this.userLoginAttempt = true;
			this.userSignUpAttempt = false;

			if (!this.isMobile) {
				this.loginPopup = true;
			} else {
				// Mobile - Redirect to login page.
				this.$router.push({
					name: 'Login'
				});
			}
		},
		closeDialogError() {
			if(this.customerUnauthorized) {
				this.otpAuthPopup = false; 
				if (!this.isMobile) {
					this.loginPopup = true;
				} else {
					// Mobile - Redirect to login page.
					this.$router.push({
						name: 'Login'
					});
				}
			}
			else {
				this.auth_error_enable = false;
				this.inputOTP1 = '';
				this.inputOTP2 = '';
				this.inputOTP3 = '';
				this.inputOTP4 = '';
				this.inputOTP5 = '';
				this.inputOTP6 = '';
			}
		},
		reversedQueue() {
			return this.queue.slice().reverse();
		},
		addToRecentSearches() {
			if (this.queue) {
				let lowerCaseArray = this.queue.map(item => item.toLowerCase());
				if (this.searchQuery != '') {
					if (!lowerCaseArray.includes(this.searchQuery.toLowerCase())) {
						this.queue.push(this.searchQuery);

						if(this.queue.length > 5) {
							this.queue.shift();
						}
						localStorage.setItem('searchQueue', JSON.stringify(this.queue));
					}
					else {
						const index = lowerCaseArray.findIndex(item => item.toLowerCase()===this.searchQuery.toLowerCase());

						if (index !== -1)
							this.queue.splice(index, 1);

						this.queue.push(this.searchQuery);

						if(this.queue.length > 5) {
							this.queue.shift();
						}
						localStorage.setItem('searchQueue', JSON.stringify(this.queue));
					}
				} 
			}
			this.searchQuery = '';
			this.closeDialog();
		},
		getAllProductsForSearchQuery(queryToBeSearched) {
			this.dialogVisible = true;
			this.epharmacySearchingLoading = true;
			this.teleconsultationSearchLoading = true;
			// Teleconsultation Search.
			let searchDoctorRequest = {
				token: '',
				typeOfUser: 'CUSTOMER',
				searchString: queryToBeSearched
			};

			axios_auth_instance_customer.post('/searchDoctor', searchDoctorRequest).then((searchDoctorResponse) => {
				this.searchResultTopicFromDB = searchDoctorResponse.data.inputString;
				this.searchSpecializationResultsFromDB = searchDoctorResponse.data.data.searchSpl;
				this.searchDoctorResultsFromDB = searchDoctorResponse.data.data.searchDoc;

				this.searchQueryComponent++;
				this.teleconsultationSearchLoading = false;
			}).catch((searchDoctorError) => {
				console.log('searchDoctorError: ', searchDoctorError);
				this.teleconsultationSearchLoading = false;
				handleError(searchDoctorError, this.$router, 'searchDoctorError', '', '');
			});

			// ePharmacy Search.
			axios_auth_instance_epharmacy_customer.get('/globalSearch', { params: { inputString: queryToBeSearched } })
			.then((globalSearchResponse) => {
				this.dialogVisible = true;

				this.searchResultTopicFromDB = globalSearchResponse.data.inputString;
				this.searchProductResultsFromDB = globalSearchResponse.data.data.searchedProduct ? globalSearchResponse.data.data.searchedProduct : [];

				this.searchQueryComponent++;
				this.epharmacySearchingLoading = false;
			}).catch((globalSearchError) => {
				this.epharmacySearchingLoading = false;
				if (globalSearchError.response.status === 500) {
					this.searchProductResultsFromDB = [];
				}
				console.log('Error fetching common home page data: ', globalSearchError);
				handleError(globalSearchError, this.$router, 'globalSearchError', '', '');
			});
			if(!epharmacySearchingLoading && !teleconsultationSearchLoading){
				this.analyticsDebounced()
			}
		},
		async bigQueryHandler() {
            let bigQueryIngestionRequest = null;
			let serviceDoctors =  this.ServiceDoctors ? this.ServiceDoctors : [];
			let specializations =  this.specializations ? this.specializations : [];
			this.token = await this.$cookies.get('customerToken');
			bigQueryIngestionRequest = {
				searchString: searchString,
				serviceDoctors: serviceDoctors,
				specializations: specializations,
				product: searchProductResultsFromDB,
				token: this.token ? this.token : '',
				deviceType: 'MiniAPP'
			}
			try {
				let bigQueryHandler = await axios.post(process.env.VUE_APP_BACKEND_URL + '/bigQueryIngestion', bigQueryIngestionRequest);
			} catch (error) {
				console.log('bigQueryWriteError', error);
			}
        },
		analyticsDebounced() {
            if (this.sendDataTimeout) clearTimeout(this.sendDataTimeout);
            this.sendDataTimeout = setTimeout(() => {
                this.bigQueryHandler();
            }, 1000);
        },
		gotoSpecializationPage(specialization) {
			this.addToRecentSearches();
			this.searchQuery = '';

			this.$router.push({
				path: '/customer/viewDoctors?type=Category&type1='+ specialization._id + '&type2=' + specialization.specialization_name
			});
		},
		gotoDoctorPage(doctor) {
			this.addToRecentSearches();
			this.searchQuery = '';

			this.$router.push({
                name: 'newBookingPage',
                params: { docId: doctor._id, walkIn: false }
            });
		},
		gotoProductPage(product) {
			this.addToRecentSearches();
			this.searchQuery = '';

			// this.$router.push({
			// 	name: 'ProductDetailsPage',
            //     params: {
            //         product_id: product.id
            //     }
			// })

			window.location.href = window.location.origin + '/customer/productdetails/' + product.id;
		},
		openDialog() {
			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogVisible = false;
		},
		updateSearch(value) {
			if(value.length > 3) {
				this.searchQuery = value;
				this.searchDoctorResultsFromDB = [],
				this.searchProductResultsFromDB = [],
				this.searchSpecializationResultsFromDB = []
				this.getAllProductsForSearchQuery(this.searchQuery);
			} else if (value.length === 0) {
				this.searchQuery = value;
				this.searchDoctorResultsFromDB = [],
				this.searchProductResultsFromDB = [],
				this.searchSpecializationResultsFromDB = []
			}
			this.dialogVisible = true;
		},
		handleClickOutside(event) {
			if (this.$refs.searchBox != undefined && this.$refs.searchBox.$el) {
				let searchContainer = document.getElementById('search-container');

				const dialogElement = this.$refs.searchBox.$el;
				if (!dialogElement.contains(event.target)) {
					if (event.target == searchContainer) {
						this.closeDialog();
						this.enterPressed = false;
					}
				}
			}
		},
		redirectToSearchPage() {
			this.$router.push({
				name: 'SearchMobilePage'
			});
		},
		redirectToCartPage() {
			if(this.token) {
				this.$router.push({
					path: '/customer/cart'
				});
			} else {
				if (this.isMobile) {
					this.$router.push({
						name: 'Login'
					});
				} else {
					this.loginPopup = true;
				}
			}
		},
		redirectToCommonHome() {
			if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
				if (this.$router.history.current.name == 'CommonHomePage') {
					this.$router.go();
				} else {
					this.$router.push({
						path: '/'
					});
				}
			}
		},
		changeLanguage(language){
			//axios call
			this.isLoading = true
			if(this.token) {
				var language_body = {
					"language": language,
					"token" : this.token,
					"typeOfUser" : "CUSTOMER"
				}
				axios_auth_instance_customer.post("/updateLanguage",language_body)
				.then((changeLanguageResponse) =>{
					this.$i18n.locale = language;
					this.$store.dispatch("changeLocale", this.$i18n.locale);
					this.isLoading = false;

				})
				.catch((changeLanguageError) =>{
					this.isLoading = false;
					if(changeLanguageError.response.status == 401){
						// this.auth_error_enable = true;
						this.openLoginPopup();
					}
					else{
						this.error_enable = true;
					}
				});
			}
			else {
				this.$i18n.locale = language;
				this.$store.dispatch("changeLocale", this.$i18n.locale);
				this.isLoading = false;
			}
			//this.$router.go();
			this.$store.dispatch("changeLocale",language);
		},
	}
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';

.profileoverlay {
	position: fixed;
	top: 75px;
	right: 20px;
	
	z-index: 9999;
}
.profileOptionsButton {
	background-color: white !important;
	display: flex;
	justify-content: space-between;
}
.userProfileChoices {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1.5px solid #E0E0E0;
	border-radius: 8px;
	padding: 5px;
	background-color: white;

	position: absolute !important;
}
.profile-icon {
	padding-right: 5px;
	cursor: pointer;
}
.userProfileDiv {
	display: flex;
	flex-direction: row;
	// justify-content: space-between;
	justify-content: right;
	background-color: #EAF4FF !important;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 3px;
	padding-bottom: 3px;
	margin-left: 20px;
	border-radius: 8px;
	// width: 60px;
	cursor: pointer;
}
.userLoginButton {
	border: 1px solid #828282;
	background-color: white !important;
}
.accountSuccessText {
	display: flex;
	justify-content: center;
}
.accountSuccessImage {
	height: 100px;
	width: 100px;
}
.accountSuccess {
	padding-top: 30px !important;
	padding-bottom: 20px !important;
	border-radius: 10px !important;
}
.signupButtonInSignup {
	margin-top: 20px;
	margin-bottom: 20px;
}
.datePicker {
	width: 25%;
}
.cancelDiv {
	display:flex; 
	flex-direction:row; 
	justify-content: center; 
	width:100%;
}
.goBackResendDiv {
	display:flex; 
	flex-direction:row; 
	justify-content: space-between; 
	width:100%;
	padding-bottom: 15px;
}
.otpTextCenter :is(input) {
	text-align: center;
}
.centered-input  input {
  text-align: center !important;
}
.centered-input {
  padding: 10px;
  text-align: center !important;
}
.otpMessageText {
	text-align: left; 
	margin-top: 16px
}
.otpHeading {
	text-align: left; 
	margin-top: 10px; 
}
.otpDiv {
	padding-left: 10px;
}
.otpAuthDialogCard {
	padding: 20px;
	border-radius: 10px !important;
}
.okButton {
	margin-left: 40%;
	margin-right: 40%;
}
.signUpButton {
	background-color: #48ACEF !important;
	color: white;
}
.newUserDiv {
	display: flex;
	justify-content: space-evenly;
	align-items: center !important;
	text-align: center;
	margin-left: 30px;
	margin-right: 30px;
}
.loginDivider {
	margin-top: 30px;
	margin-bottom: 20px;
}
.sendOTPButtonDiv {
	
}
.sendOTPButton {
	width: 40%;
	background-color: #48ACEF !important;
	color: white;
}
.mobileNumTextField {
	padding-right: 20px;
}
.mobileNumInnerDiv {
	display: flex; 
	justify-content: space-around;
}
.mobileNumDiv {
	text-align: left;
	padding-top: 20px;
}
.welcomeBackText {
	text-align: left; 
	margin-top: 16px;
	font-size: large;
}
.loginText {
	text-align: left; 
	margin-top: 10px;
	font-size: x-large;
}
.loginDiv {
	padding-left: 10px;
}
.backButton {
	width: 25x;
	height: 25px;
	display: flex !important;
	justify-content: flex-start !important;
	margin-bottom: 10px;
}
.closeButton {
	width: 25x;
	height: 25px;
	display: flex !important;
	justify-content: flex-end !important;
}
.loginCard {
	padding: 20px;
	border-radius: 10px !important;
}
.loginDialog {
	width: 80% !important;
}
.listItemButton {
	background-color: white !important; 
	text-transform: none;
	width: 92%;
	justify-content: space-between; 
	font-weight: 400;
	font-size: 16px;
}
.listResults {
	color: #1467BF;
	display: flexbox;
}
.no-result {
	padding-top: 30px;
}
.no-result-msg {
	color: #828282;
	font-weight: 400;
	font-size: 18px;
	padding-top: 10px;
}
.line {
    margin-left: 20px;
    width: 70px;
    height: 1px;
    background-color: #1467BF;
}
.line2 {
	margin-left: 2%;
	margin-right: 2%;
	width: 95%;
	height: 1px;
	background-color: #E0E0E0;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	
	z-index: 9999;
}
.headingOfSearchComponent {
    color: #1467BF;
    text-transform: uppercase;
    text-align: start;
    font-weight: 600;
    font-size: $normal-text-font-size-web;
    padding-top: 20px;
    padding-left: 20px;
	margin-bottom: 0px;
}
.fullComponentOfSearchWeb {
    width: 55%;
	max-height: 70vh;
	overflow: scroll;
	overflow-x:  hidden;
    background-color: white;
    font-family: $font-family-default !important;
    position:absolute;
    left: 154px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    
    @media (max-width: 600px) {
        display: none;
    }
    @media (min-width: 601px)  {
        top: 72px;
    }
    @media (min-width: 1265px) {
        left: 203px;
    }
    
}
.searchItemsTab {
	padding-top: 10px;
    padding-left: 20px;
}
.show-search-results-Section {
	padding-bottom: 8px;
}
.item {
    color: #333333;
	font-size: 16px;
    font-weight: 400;
	background-color: #F4F6FA !important;
}
.common-header-container {
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 600px) {
		margin: $margin-header-y-mobile 0 $margin-header-y-mobile $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		margin: $margin-header-y-medium calc($margin-header-x-medium / 2) $margin-header-y-medium $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		margin: $margin-header-y-web calc($margin-header-x-web / 2) $margin-header-y-web $margin-header-x-web;
	}
}

.common-header-container .search-segment, .common-header-container .language-segment, .common-header-container .userProfileMainDiv {
	@media (max-width: 600px) {
        display:  none !important;
	}
}
.search-icon {
	// width: 48px;
	// height: 48px;
	object-fit: fill;
	
	@media (max-width: 600px) {
		
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none;
	}

	@media (min-width: 1265px) {
		display: none;
	}
}
.search-segment {
	width: 60%;
	max-width: 480px;

	@media (max-width: 600px) {
		
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-left: 32px;
	}

	@media (min-width: 1265px) {
		padding-left: 64px;
	}
}
.search-bar {
	border-radius: 8px;
}

.header-right-component {
	// width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 600px) {
		gap: 8px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		gap: 16px;
	}

	@media (min-width: 1265px) {
		gap: 24px;
	}
}

.language-segment {
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 600px) {
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		gap: 8px;
	}

	@media (min-width: 1265px) {
		gap: 12px;
	}
}

.language-select {
	max-width: 65px;
}
.header-left-component {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	// gap: 24px;
}
.brand-logo-class {
	width: auto;
	object-fit: contain;

	@media (max-width: 600px) {
        height: 40px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		height: 48px;
	}

	@media (min-width: 1265px) {
		height: 48px;
	}
}

.cart-icon {
	position: relative;

	@media (min-width: 601px) and (max-width: 1264px) {
		margin-right: -20px;
	}
}
.products-length-container {
	position: absolute;
    top: -10px;
    right: -10px;
	width: 20px;
    height: 20px;
    border-radius: 10px;
}
.products-length {
	width: 18px;
    height: 18px;
    border-radius: 50%;
}
</style>

<style lang="scss">
@import '../../scss/classes.scss';

.language-select .v-input__slot {
	padding: unset !important;
	margin: unset !important;
}

.search-bar .v-input__slot {
	padding: 8px !important;
}

.language-select .v-text-field__details {
	display: none !important;
}
</style>